<template>
  <div class="bv627">
    <div class="bgx">
      <img class="hero-img"
        src="https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/BV627.png">
      <p class="welcome-title" style="margin-top: 0px;">真遗憾，被你发现了。</p>
      <p class="welcome-title" style="margin-top: 0px;">欢迎访问BV627全球唯一网站</p>

      <!-- 播放按钮 -->
      <button id="play-button">播放品牌声音</button>
    </div>

    <h1 style="margin-left: 20px; margin-top: 10px; color: #fff000;">BV627今日视点</h1>

    <div class="scroll-container">
      <div class="card-container" id="card-container">
        <div class="card"
          style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/znn.png');"
          data-title="今日视点 today de viewpoiot" data-subtitle="我要带你看 BV627 在迷惑挑战吗？" data-align="center"></div>
        <!-- <div class="card"
          style="background-image: url('https://puui.qpic.cn/vpic_cover/k3532ms7231/k3532ms7231_1694584608_hz.jpg/1280');"
          data-title="真的很“环保”" data-subtitle="Carrot说的对呀，直接拿现成的，为什么还要重新设计？简直是对能源的浪费！" data-align="top-left"
          data-badge="news"></div> -->
        <!-- <div class="card"
          style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/<placeholder>.jpg');"
          data-title="点歌：PENTIMENT（616警告（（（）" data-subtitle="PURE MEMORY GREAT GREAT 4 U，TRACK LOST BAD BAD 2 ME。" data-align="bottom-left" data-badge="event"></div> -->
        <div class="card"
          style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/340AD0C5F1D9D14D447E37F42373235A.jpg');"
          data-title="我要给祖上十八代申请直播间。" data-subtitle="精彩因我而优，欢乐随我而动。" data-align="bottom-left" data-badge="event"></div>
        <div class="card"
          style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/dot.jpg');"
          data-title="我患抑郁症了。" data-subtitle="为什么说什么话都要带句号。诶好奇怪。为什么我也开始带句号了。" data-align="bottom-left"
          data-badge="latest"></div>
        <div class="card"
          style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/assets-627/ang.png');"
          data-title="请熟读BV627综合物种中心标配语言" data-subtitle="1.我得了“玉玉症”
            2.小（字母）蛋仔派对
            3.闹事的
            4.申请直播间，反复申请直播间，还有直播间
            5.被受害的人是有错的
            6.管不了事的我和我" data-align="bottom-left"></div>
        <!--<div class="card" style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/image4.jpg');" data-title="标题4" data-subtitle="小标题4" data-align="bottom-left" data-badge="news"></div>
            <div class="card" style="background-image: url('https://vds-img-1301106215.cos.accelerate.myqcloud.com/vds-pub-fonts/image5.jpg');" data-title="标题5" data-subtitle="小标题5" data-align="bottom-right"></div>-->
      </div>
    </div>

    <div class="indicator-container" id="indicator-container">
    </div>

    <!-- <h1 style="margin-left: 20px; margin-top: 10px; color: #fff000;">BV627开关机视频</h1> -->

    <h1 style="margin-left: 20px; margin-top: 10px; color: #fff000;">在Q&amp;A Time可以（？）做的抽象事情</h1>
    <ul>
      <li>精彩直播台 绽放新精彩 您现在正在收看的是V—D—S—1——直播频道 VDS1是KLCF小钟团黑金VIP成员 我和汪定芬是夫妻 嗲嗲夫妻嗲嗲爱 对立你带我走吧对立 ██616还我对立！！！！！</li>
      <li>最后就是A西八……最后就是A18 Pro的超强性能</li>
      <li>点歌：PENTIMENT（616警告（（（）</li>
      <li>Ask: 欢乐中国英特尔MOMO频道2024年改版包装</li>
      <li>随机复制一条别人的幸运提问并且只改几个字就再发</li>
      <li>我趣了我问了 但就是回答不上来的问题</li>
      <li>我——不——曾——忘——怀————（震声）</li>
      <li>\PARN/（《Q&amp;A Time》EP10配音的某段无法过审的素材）</li>
      <li>你知道VDS的八月频道吗？是不是在，8月58号</li>
      <li>本节目由儿童学习广播，this progr@ is procast i childr@k</li>
      <li>KohaD：请不要游玩《舞萌DX 2077》</li>
      <li>人体发声器.wav.tar.xz分子</li>
      <li>严禁拉黑或辱骂以及骚扰我本人，我xQc是好人！</li>
      <li>#直播频道改革运动# 建议“VDSlive24”网络直播频道调整为“VDS NEWS 24”并更改“VDS NEWS 24（原VDSlive24）”频道节目播出内容为24小时不间断网络热门新闻资讯短视频轮播</li>
      <li>童年新时光.wacca.wtf</li>
      <li>王炸！727连炸！拉嗨不谢！！！</li>
      <li>6月27号的几个意见建议都是对BV627的健康发展有益的！！！！！！！！！！</li>
      <li>随机拿走桃井爱莉/约尔/白教练（wt</li>
      <li>如果您喜欢这段视频的话，请来个“三连”“关注”以及“重大推荐”哦</li>
      <li>BV627电视电视开关机，开机。啊啊啊啊开机辣开机辣！！！关机，关机辣关机辣</li>
    </ul>

    <p style="margin: 10px; font-size: 13px; font-family: 'MiSans', Arial, sans-serif; color: rgb(47, 47, 47);">
      *本页面的设计和动画效果并不遵循VDS
      Horizra设计语言规范，所有页面上的设计和动效仅代表页面设计者，与唯迪爱思官方立场无关。页面中所使用的“BV627”名称是由“TV768”名称衍生而来，TV768是TV768工作室持有版权的标志，所有相关logo的设计均归该工作室所有。如有任何疑问或侵权问题需要解答，请联系TV768。
    </p>

    <div id="vc-footer"></div>

    <!-- 隐藏的音频播放器 -->
    <audio id="opening-audio" src="./627-media/opening.mp3" style="display:none;"></audio>
  </div>
</template>

<script>
export default {
  name: "vc-bv627",
  mounted() {
    this.setCardHeight();
    this.initializeCards();

    document.title = "BV627全球唯一官方网站·精彩的627 让生活更色彩。- VDS·欢乐，正发生。";

    document.body.style.backgroundColor = "#ff0000";

    const cardContainer = document.getElementById('card-container');
    const indicators = document.getElementById('indicator-container');
    const cards = cardContainer.querySelectorAll('.card');

    // 创建圆点指示器并添加点击事件
    cards.forEach((_, index) => {
      const indicator = document.createElement('div');
      indicator.classList.add('indicator');
      if (index === 0) indicator.classList.add('active');
      indicators.appendChild(indicator);

      // 点击圆点时滚动到对应的卡片
      indicator.addEventListener('click', () => {
        const cardWidth = cards[0].offsetWidth + 20; // 包含gap的宽度
        cardContainer.scrollTo({
          left: index * cardWidth,
          behavior: 'smooth' // 平滑滚动
        });
      });
    });

    const updateIndicators = () => {
      const scrollLeft = cardContainer.scrollLeft;
      const cardWidth = cards[0].offsetWidth + 20; // 包含gap的宽度
      const currentIndex = Math.round(scrollLeft / cardWidth);
      indicators.querySelectorAll('.indicator').forEach((indicator, index) => {
        indicator.classList.toggle('active', index === currentIndex);
      });
    };

    document.getElementById('play-button').addEventListener('click', function () {
      var audio = document.getElementById('opening-audio');
      audio.play();
    });

    // 监听卡片容器的滚动事件
    cardContainer.addEventListener('scroll', updateIndicators);

    // 监听窗口大小改变事件，重新计算视口高度
    window.addEventListener('resize', this.setCardHeight);
  },
  beforeUnmount() {
    document.body.style.backgroundColor = "#000000";
  },
  methods: {
    setFavicon(url) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = url;
    },
    setCardHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      const cards = document.querySelectorAll('.card');
      cards.forEach(card => {
        card.style.height = `calc(var(--vh, 1vh) * 80)`; // 动态计算 80vh
      });
    },
    initializeCards() {
      const cards = document.querySelectorAll('.card');
      cards.forEach(card => {
        const title = card.getAttribute('data-title');
        const subtitle = card.getAttribute('data-subtitle');
        const align = card.getAttribute('data-align');
        const badge = card.getAttribute('data-badge');

        const content = document.createElement('div');
        content.classList.add('card-content', align);

        if (badge) {
          const badgeElem = document.createElement('div');
          badgeElem.classList.add('badge', `badge-${badge}`);
          switch (badge) {
            case 'latest':
              badgeElem.textContent = '最新发布';
              break;
            case 'news':
              badgeElem.textContent = '新闻';
              break;
            case 'event':
              badgeElem.textContent = '特别活动';
              break;
          }
          content.appendChild(badgeElem);
        }

        const titleElem = document.createElement('div');
        titleElem.classList.add('title');
        titleElem.textContent = title;

        const subtitleElem = document.createElement('div');
        subtitleElem.classList.add('subtitle');
        subtitleElem.textContent = subtitle;

        content.appendChild(titleElem);
        content.appendChild(subtitleElem);
        card.appendChild(content);
      });
    }
  }
};
</script>

<style scoped>
.bv627 {
  font-family: 'Comicsans', Arial, sans-serif;
}

.scroll-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  /* 隐藏水平滚动条 */
}

.welcome-titles {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.welcome-title {
  font-size: 60px;
  /* 加大标题字号 */
  padding: 0;
  /* 移除 padding */
  position: relative;
  animation: glitch 1s infinite;
  color: #00ff00;
}

.card-container {
  display: flex;
  gap: 20px;
  /* 卡片之间的间距 */
  overflow-x: auto;
  /* 横向滚动 */
  padding: 0 10vw;
  /* 安全区 */
  box-sizing: border-box;
  width: 100%;
  scroll-snap-type: x mandatory;
  /* 使滚动有吸附效果 */
}

.card-container::-webkit-scrollbar {
  display: none;
  /* 隐藏滚动条 */
}

.card {
  width: calc(100vw - 10vw);
  /* 卡片宽度为设备屏幕宽度减去安全区 */
  height: 80vh;
  /* 初始高度设置为80vh，会被JavaScript覆盖 */
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  /* 圆角 */
  flex: 0 0 auto;
  /* 保持卡片宽度不变 */
  scroll-snap-align: center;
  /* 滚动对齐 */
  position: relative;
  /* 相对定位以包含内部绝对定位元素 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bgx {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.hero-img {
  height: 50px;
  margin-top: 80px;
}

::v-deep .card-content {
  position: absolute;
  color: white;
}

::v-deep .title {
  font-family: 'MiSans-Bold', Arial, sans-serif;
  font-size: 60px;
  /* 加大标题字号 */
}

::v-deep .subtitle {
  font-family: 'MiSans', Arial, sans-serif;
  font-size: 20px;
}

::v-deep .badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

::v-deep .badge-latest {
  background-color: yellow;
  color: black;
}

::v-deep .badge-news {
  background-color: green;
  color: white;
}

::v-deep .badge-event {
  background-color: purple;
  color: white;
}

::v-deep .center {
  justify-content: center;
  align-items: center;
}

::v-deep .top-left {
  top: 10px;
  left: 10px;
  text-align: left;
}

::v-deep .top-right {
  top: 10px;
  right: 10px;
  text-align: right;
}

::v-deep .bottom-left {
  bottom: 10px;
  left: 10px;
  text-align: left;
}

::v-deep .bottom-right {
  bottom: 10px;
  right: 10px;
  text-align: right;
}

.indicator-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
}

::v-deep .indicator {
  width: 10px;
  height: 10px;
  background-color: grey;
  border-radius: 50%;
  margin: 0 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  /* 添加点击手型光标 */
}

::v-deep .indicator.active {
  background-color: white;
}

@media (max-width: 767px) {
  ::v-deep .title {
    font-size: 45px;
  }

  ::v-deep .subtitle {
    font-size: 15px;
  }
}

/* 隐藏按钮样式 */
#play-button {
  font-size: 20px;
  background-color: #fff000;
  color: rgb(0, 255, 0);
  border: none;
  width: 200px;
  height: 70px;
  border-radius: 5px;
  cursor: pointer;
}
</style>