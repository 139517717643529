<template>
  <div class="scroll-wrapper">
    <!-- 二级导航栏 -->
    <div ref="subNav" class="sub-nav">
      <div class="sub-nav-left">
        <p>VDS Studio Nanjing</p>
      </div>
      <div class="sub-nav-right">
        <button class="experience-btn" @click="handleExperienceClick">联系我们</button>
      </div>
    </div>

    <!-- 影棚介绍 -->
    <div class="studio-info">
      <h1 class="bold">欢迎来到 VDS Studio Nanjing</h1>
      <p>
        VDS Studio Nanjing 是一个综合性的创意空间。
        影棚总面积达 <strong>1200 平方米</strong>，棚高 <strong>10 米</strong>，是你创作与活动的理想场所。
      </p>
      <p>
        这个页面的其他内容还在紧锣密鼓准备中，但我们目前已经为你准备好了全景视图的VR看棚功能。
      </p>

      <div class="studio-intro">

        <!-- 全景体验按钮，跳转新页面 -->
        <div class="panorama-section fade-in">
          <h2 class="title bold">全景VR看棚</h2>
          <p>点击下方按钮开始全景视图体验。</p>
          <div class="panorama-link-container">
            <a 
              href="/services/studio-nanjing/panorama" 
              target="_blank" 
              class="enter-panorama-btn"
            >
              开始
            </a>
          </div>
        </div>

      </div>

      <!-- 联系方式 -->
      <div class="contact-info fade-in">
        <h2 class="bold">联系我们</h2>
        <p>如需预约或咨询，请通过该电子邮件地址与我们联系：</p>
        <ul>
          <li><strong class="bold">邮箱：</strong><a href="mailto:bd@vds.pub">bd@vds.pub</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VDS-Studio-Nanjing",
  data() {
    return {
      showMoreFloorPlans: false,
      observer: null,
    };
  },
  mounted() {
    document.title = "VDS Studio Nanjing 线下场地 - VDS·欢乐，正发生。";
    window.addEventListener("scroll", this.handleScroll);
    this.initObserver();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    handleExperienceClick() {
      window.open("mailto:bd@vds.pub");
    },
    handleScroll() {
      const subNav = this.$refs.subNav;
      if (subNav) {
        if (window.scrollY > 100) {
          subNav.classList.add('hidden');
        } else {
          subNav.classList.remove('hidden');
        }
      }
    },
    initObserver() {
      const options = {
        threshold: 0.1,
      };
      this.observer = new IntersectionObserver(this.handleIntersect, options);
      const elements = document.querySelectorAll('.fade-in');
      elements.forEach(el => {
        this.observer.observe(el);
      });
    },
    handleIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          this.observer.unobserve(entry.target);
        }
      });
    },
    toggleFloorPlans() {
      this.showMoreFloorPlans = !this.showMoreFloorPlans;
      this.$nextTick(() => {
        const newElements = document.querySelectorAll('.additional-floor-plans .fade-in');
        newElements.forEach(el => {
          if (!el.classList.contains('visible')) {
            this.observer.observe(el);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.title {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #006aff;
}

.bold {
  font-family: 'MiSans-Bold', Arial, sans-serif;
}

.scroll-wrapper {
  color: #fff;
}

/* 二级导航栏样式 */
.sub-nav {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 999;
  opacity: 1;
}

.studio-intro {
  color: black;
}

.sub-nav.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.sub-nav-left p {
  color: white;
  font-size: 18px;
}

.sub-nav-right {
  display: flex;
  align-items: center;
}

.experience-btn {
  background-color: #006aff;
  color: #fff;
  border: none;
  padding: 8px 24px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
}

.experience-btn:hover {
  background-color: #0051cc;
}

/* 影棚介绍样式 */
.studio-info {
  padding: 120px 20px 80px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.studio-info h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.studio-info p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 30px;
  text-align: center;
}

.studio-info ul {
  list-style: none;
  padding: 0;
}

.studio-info li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.studio-info h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.studio-info h2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: #006aff;
}

.advantages {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.advantages li {
  flex: 1 1 calc(33.333% - 30px);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.advantages li.visible {
  opacity: 1;
  transform: translateY(0);
}

.advantages h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.advantages p {
  font-size: 1rem;
  line-height: 1.6;
}

/* 服务项目样式 */
.services {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 40px;
}

.service-item {
  flex: 1 1 calc(33.333% - 30px);
  text-align: center;
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.service-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.service-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.service-item img:hover {
  transform: scale(1.05);
}

.service-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-item p {
  font-size: 1rem;
  line-height: 1.6;
}

.footnote {
  font-size: 0.9rem;
  color: #777;
  margin-top: 10px;
}

/* 使用环境示例样式 */
.studio-examples {
  margin-top: 60px;
}

.example-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.example-item {
  flex: 1 1 calc(33.333% - 30px);
  text-align: center;
  opacity: 0;
  color: gray;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.example-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.example-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.example-item img:hover {
  transform: scale(1.05);
}

.example-item p {
  margin-top: 15px;
  font-size: 1rem;
}

/* 全景体验按钮 */
.panorama-section {
  margin-top: 60px;
  color: #fff;
  position: relative;
}

.panorama-link-container {
  text-align: center;
  margin-top: 20px;
}

.enter-panorama-btn {
  display: inline-block;
  padding: 10px 20px;
  background: #006aff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.enter-panorama-btn:hover {
  background: #0051cc;
}

/* 联系方式样式 */
.contact-info {
  margin-top: 60px;
  padding: 40px;
  background-color: #006aff;
  color: #fff;
  border-radius: 10px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.contact-info.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-info h2 {
  color: #fff;
}

.contact-info p,
.contact-info ul,
.contact-info li {
  color: #fff;
}

.contact-info a {
  color: #fff;
  text-decoration: underline;
}

.contact-info ul {
  margin-top: 20px;
}

.contact-info li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* 展开动画 */
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.6s ease, opacity 0.6s ease;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 1000px;
  opacity: 1;
}

.additional-floor-plans {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.floor-plan-item {
  flex: 1 1 calc(33.333% - 20px);
  text-align: center;
}

.floor-plan-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.floor-plan-item p {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .advantages li,
  .service-item,
  .example-item,
  .floor-plan-item {
    flex: 1 1 100%;
  }

  .studio-info {
    padding: 80px 10px 40px;
  }

  .studio-info h1 {
    font-size: 2rem;
  }

  .title {
    font-size: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .sub-nav-left {
    margin-left: 15%;
  }

  .sub-nav-right {
    margin-right: 15%;
  }
}

@media (max-width: 1023px) {
  .sub-nav-left {
    margin-left: 20px;
  }

  .sub-nav-right {
    margin-right: 20px;
  }
}
</style>