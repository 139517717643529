// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 正确导入 router
import VueLazyload from 'vue-lazyload';

const app = createApp(App);

app.use(VueLazyload, {
    preLoad: 1.3,
    error: 'path/to/error-image.png',
    loading: 'path/to/loading-image.gif',
    attempt: 1
  });
app.use(router); // 使用路由
app.mount('#VDSpubAPP'); // 挂载应用